import * as React from 'react';
import { Image } from 'react-bootstrap'; 
import backImage from '../assets/images/Section1_Background.png'; 
import '../assets/styles/section1.css'; 

interface Props {
}

interface State {
}

class Section1 extends React.Component<Props, State> {
    public state = {
    };

    public render() {
        return (
            <div className="page-section1">
                <div className="section-background1" >
                    <Image src={backImage} className="background-img1" />
                </div>            
            </div>
        );
    }
}

export default Section1;
