import * as React from 'react';
import { Col, Image, Row } from 'react-bootstrap'; 
import '../assets/styles/section2.css'; 

interface Props {
}

interface State {
}

class Section2 extends React.Component<Props, State> {
    public state = {
    };

    public render() {
        return (
            <div className="page-section2">
                <div className="section-background">
                    <div className="content-wrapper" >
                    <Row className="content">
                        <div className="header-block">
                            Introducing Better Balance
                        </div>
                        <div className="text-content">
                        Eating healthy should be easy and enjoyable. At Better Balance, we offer delicious and nutritious plant-based alternatives that fit into your favorite recipes without sacrificing flavor.                        
                        </div>
                    </Row>
                    </div>
                    <div className="content-wrapper" >
                    <Row className="content">
                        <div className="text-content">
                        Your body deserves better fuel. Our straightforward label can tell you all about our quality plant-based alternatives, helping you make small decisions that make big changes.
                        </div>
                    </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section2;
