import { Users } from '../data/users'; 
import { loginErrors } from '../data/loginErrors'; 
import Cookies from 'universal-cookie';

export const userLogin = (userName: string, userPass: string) => {
    const userData = Users.find(x => x.userName.toLowerCase() == userName.toLocaleLowerCase()); 
    let returnMessage; 
    
    if (userData) {
        if (userData.userPass !== userPass) {            
            returnMessage = loginErrors.find(x => x.name === 'uPass')?.message; 
        }
        else {
            const cookieName = 'bbonevoiceuser'; 
            const cookies = new Cookies(); 
            cookies.set(cookieName, userName, {maxAge: 86400});
            return null;  
        }
    } else {
        returnMessage = loginErrors.find(x => x.name === 'uName')?.message; 
    }

    return returnMessage; 
}

export const userLogout = () => {
    const cookies = new Cookies();
    cookies.remove('bbonevoiceuser'); 
}

export const getActiveUser = () => {
    const cookies = new Cookies(); 
    const user = cookies.get('bbonevoiceuser'); 
    if (user)
        return user; 
    else return null; 
}