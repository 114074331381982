import { ErrorMessage } from "../models/errorMessage"; 

export const loginErrors: ErrorMessage[] = [
    {
        name: 'uName', 
        message: 'Cannot find User Name, please re-enter...' 
    }, 
    {
        name: 'uPass',
        message: 'Password and User Name do not match, try again...' 
    }
]
