import { User } from '../models/user'; 

export const Users: User[] = [
    {
        userName: 'GreenTeam',
        userPass: 'GT4betterbalance!',
    },
    {
        userName: 'Sigmabb',
        userPass: 'Sigma4BetterBalance',    
    },
    {
        userName: 'bbPartners',
        userPass: 'WeR4betterbalance1!',    
    }
]