import React, { Component } from 'react';
import { Container } from 'react-bootstrap'; 
import * as userService from './services/user'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/App.css';
import Login from './components/Login'; 
import Header from './components/Header'; 
import Section1 from './components/Section1'; 
import Section2 from './components/Section2'; 
import Videos from './components/Videos'; 
import Footer from './components/Footer'; 

interface Props {

}

interface State {
  showLogin: boolean;
  activeUser?: string;
}

class App extends Component<Props,State> {
  public state = {
    showLogin: true, 
    activeUser: undefined,
  }

  componentDidMount = () => {
    const activeUser = userService.getActiveUser(); 
    if (activeUser)
      this.setState({activeUser: activeUser, showLogin: false}); 
  }

  public handleSelect = (eventKey: string | null) => {
    if (eventKey != null) {
      alert(eventKey); 
    }    
  }

  public toggleModal = () => {
    this.setState({showLogin: !this.state.showLogin}); 
  }

  public setUserState = (userName: string) => {
    this.setState({ activeUser: userName }); 
  }

  public logoutUser = () => {
    userService.userLogout(); 
    this.setState({ activeUser: undefined, showLogin: true });
  }

  public scrollToVideos = () => {
    const anchor = document.querySelector('#videos'); 
    anchor?.scrollIntoView({behavior:'smooth', block: 'center'}); 
  }

  public render() {    
      return (
        <Container className="app">
          <Login 
            setUserState={this.setUserState}
            showModal={this.state.showLogin}
            closeModal={this.toggleModal}
          />
          <Header 
            activeUser={this.state.activeUser}
            logoutUser={this.logoutUser}
            toggleModal={this.toggleModal}             
            scroll={this.scrollToVideos}
          />
          <Section1/>
          <Section2/>          
          <div id="videos">
            <Videos activeUser={this.state.activeUser}/>
          </div>
          <Footer/>
        </Container>    
      )			    
  }
}

export default App; 
