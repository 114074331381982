import * as React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap'; 
import '../assets/styles/footer.css'; 

interface Props {
}

interface State {
}

class Footer extends React.Component<Props, State> {
    public state = {
    };

    public render() {
        return (
            <>
            <div className="footer-text">
                <Row style={{ fontWeight: "bold" }}><Col>Where we are:</Col></Row>
                <Row><Col className="footer-reg">Europe <span className='add-dot'>HQ</span> Madrid, Spain</Col></Row>
                <Row><Col>
                    <Button 
                        variant='link'
                        target="_blank"
                        href="mailto:contacto@betterbalancefoods.com"
                        >contacto@betterbalancefoods.com
                    </Button>
                </Col></Row>
                <Row><Col>USA <span className='add-dot'>HQ</span> Dallas, USA</Col></Row>
                <Row><Col>
                    <Button 
                        variant='link'
                        target="_blank"
                        href="mailto:ContactUS@betterbalancefoods.com"
                        >ContactUS@betterbalancefoods.com
                    </Button>
                </Col></Row>
                <Row><Col>LATAM <span className='add-dot'>HQ</span> Monterrey, Mexico</Col></Row>
                <Row><Col>
                    <Button 
                        variant='link'
                        target="_blank"
                        href="mailto:contactomx@betterbalancefoods.com"
                        >contactomx@betterbalancefoods.com
                    </Button>
                </Col></Row>
                <Row className='footer-copyright-wrapper'>
                    <Col className='footer-copyright'>
                        © BETTER BALANCE USA 2022
                    </Col>
                </Row>
            </div>            
            </>
        );
    }
}

export default Footer;