import * as React from 'react';
import * as models from '../models'; 
import VideoLeft from './VideoLeft'; 
import VideoRight from './VideoRight'; 
import { videos } from '../data/videos'; 
import '../assets/styles/video.css'; 


interface Props {
    activeUser?: string; 
}

interface State {
}

class Videos extends React.Component<Props, State> {
    public state = {
    };

    public render() {
        const { activeUser } = this.props; 

        const items = () => {                        
            return (
                <>                
                    {activeUser && videos && videos.filter(x => x.acl.includes(activeUser.toLowerCase())).map((v: models.Video, i: number) => {
                        if(i%2===0)
                            return <VideoLeft key={i} video={v} />
                        else return <VideoRight key={i} video={v} />
                    })}
                </>
            );             
        }
        return (   
            <>        
            {items()}            
            </>
        ); 
    }    
}

export default Videos;