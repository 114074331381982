import * as React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'; 
import * as userService from '../services/user'; 
import '../assets/styles/login.css'; 

interface Props {
    showModal: boolean;
    closeModal: () => void;
    setUserState: (u:string) => void;  
}

interface State {
    userName: string; 
    userPass: string; 
    errorMessage: string | null; 
}

class Login extends React.Component<Props, State> {
    public state = {
        userName:"", 
        userPass:"" , 
        errorMessage: null, 
    };

    public resetFormStateAndClose = () => {
        this.setState({userName:"", userPass:"", errorMessage: null});
        this.props.closeModal(); 
    }    

    public userLogin = (e: any) => {
        e.preventDefault(); 
        const { userName, userPass } = this.state; 
        const isUserValid = userService.userLogin(userName, userPass);

        if (isUserValid == null) {
            this.props.setUserState(userName); 
            this.props.closeModal(); 
        } else {
            this.setState({errorMessage: isUserValid}); 
        }
    }

    public render() {
        return (
            <Modal centered={true} show={this.props.showModal} onHide={this.props.closeModal}>
                <Modal.Header closeButton > 
                    <div className="error">{this.state.errorMessage}</div>
                </Modal.Header>
                <Form className="login-form" onSubmit={this.userLogin}>
                    <Modal.Body>
                        <Form.Group as={Row} controlId="userName">
                            <Form.Label column sm="4">User Name</Form.Label>                            
                            <Col sm="8">
                                <Form.Control type="text" placeholder='Enter User Name...' onChange={(e) => this.setState({userName: e.target.value})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="userPass">
                            <Form.Label column sm="4">Password</Form.Label>
                            <Col sm="8">
                                <Form.Control type="password" placeholder='Enter Password...' onChange={(e) => this.setState({userPass: e.target.value})} />
                            </Col>
                        </Form.Group>                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={this.resetFormStateAndClose} >
                            CANCEL
                        </Button>
                        <Button variant='primary' type="submit" >
                            LOGIN
                        </Button>                    
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default Login;