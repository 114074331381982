import { Video } from '../models/video'; 

export const videos: Video[] = [
    {        
        name: 'welcome to better balance',
        description: 'Helio Castano, Sigma VP of Plant Based Global and CEO of Better Balance welcomes you to the Better Balance team and invites you to help build a better world together.  This video is designed to walk you step by step in understanding what we are trying to achieve as a Better Balance team, why it is important, and how we collaborate to make it happen. ', 
        location: 'https://bbonevoicestorageaccount.blob.core.windows.net/media/SigmaBetterBalance.mp4', 
        thumbFileName: 'TM2.jpg',
        acl: ['greenteam', 'sigmabb', 'bbPartners'], 
    }
]