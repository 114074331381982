import * as React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap'; 
import * as models from '../models'; 

interface Props {
    video: models.Video; 
}

interface State {
}

class VideoLeft extends React.Component<Props, State> {
    public state = {
    };

    public render() {
        const { video } = this.props; 

        return (
            <Card className="video-card">
                <Card.Body>
                    <Row>
                    <Col sm="6">
                        <video poster={process.env.PUBLIC_URL + video.thumbFileName} controls preload='metadata'>
                            <source src={video.location} type="video/mp4" />
                        </video>
                    </Col>
                    <Col sm="6" className="video-card-text-area">
                        <Row className="video-name">
                            {this.props.video.name}
                        </Row>
                        <Row>
                            {this.props.video.description}
                        </Row>
                    </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}

export default VideoLeft;