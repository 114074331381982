import * as React from 'react';
import { Button, Col, Image, Dropdown, Nav, Navbar, Row, NavLink, NavDropdown } from 'react-bootstrap'; 
import logo from '../assets/images/Full_Logo_2_Color.png'; 
import '../assets/styles/header.css'; 

interface Props {
    activeUser?: string; 
    logoutUser: () => void; 
    toggleModal: () => void;
    scroll: () => void; 
}

interface State {
}

class Header extends React.Component<Props, State> {
    public state = {
    };

    public render() {
        const isLoggedIn = this.props.activeUser ? true : false;
        
        return (
            <div className="header">
              <div className="header-bar">
                  <div className="header-inner">
                    <Col sm="2" className="header-title-logo" >
                          <Image src={logo} />
                    </Col>                      
                    <Col sm="9" className="header-nav" >                                                
                        <Navbar className="header-nav">
                            <Nav>
                                <Nav.Item
                                    className="header-nav-item"
                                    onClick={isLoggedIn ? this.props.logoutUser : this.props.toggleModal}
                                >{isLoggedIn ? 'Logout' : 'Login'}</Nav.Item>
                                {isLoggedIn &&
                                    <Nav.Item
                                        className="header-nav-item"
                                        onClick={this.props.scroll}
                                    >Videos</Nav.Item>
                                }
                                <NavDropdown title="Global Sites" className="header-nav-item" autoClose="outside">
                                    <NavDropdown.Item className="header-nav-item" target="_blank" href="https://betterbalancefoods.us/">USA</NavDropdown.Item>
                                    <NavDropdown.Item className="header-nav-item" target="_blank" href="https://betterbalancefoods.mx/">Mexico</NavDropdown.Item>
                                    <NavDropdown.Item className="header-nav-item" target="_blank" href="https://www.betterbalanceshop.com/">Spain</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar>
                    </Col>
                  </div>
              </div>
          </div>
        );
    }
}

export default Header;